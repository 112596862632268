import React from 'react';
import { useEffect, useState } from 'react';

const LogoColumn = ({ width, idx }) => {
  const el = [
    { letter: "p", top: 0.007, left: 0, rotate: -36 },
    { letter: "i", top: -0.045, left: -0.0085, rotate: -23.9 },
    { letter: "r", top: -0.077, left: -0.001, rotate: -10.5 },
    { letter: "o", top: -0.083, left: 0.006, rotate: 0 },
    { letter: "u", top: -0.0465, left: -0.008, rotate: 23 },
    { letter: "e", top: 0.014, left: -0.007, rotate: 32 },
    { letter: "t", top: 0.06125, left: -0.015, rotate: 17 },
    { letter: "t", top: 0.0765, left: -0.007, rotate: 0 },
    { letter: "e", top: 0.058125, left: -0.004, rotate: -17 } 
  ];
  return (
    <div className="column" style={{
      height: `${width * 0.15}px`,
      top: `${width * el[idx].top}px`,
      left: `${width * el[idx].left}px`,
      transform: `rotate(${el[idx].rotate}deg)`
    }}>
      <span style={{animationDelay: `${idx * 0.04}s`}}>{el[idx].letter}</span>
    </div>
  )
}

const Logo = ({ style }) => {
  const [factor, setFactor] = useState(0.8);
  const [width, setWidth] = useState(0);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWidth(width * factor);
    setFactor(width < 992 ? 0.8 : 0.5);
  }
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  });
  return (
    <h1 className="pirouette-logo" style={{
      paddingLeft: `${width * 0.035}px`,
      paddingTop: `${width * 0.088}px`,
      paddingRight: "60px",
      fontSize: `${width * 0.1176}px`,
      letterSpacing: `${width * 0.047}px`,
      height: `${width * 0.3}px`,
      flexShrink: 0,
      ...style
    }}>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(idx => (
        <LogoColumn key={idx} width={width} idx={idx} />
      ))}
      <div className="description" style={{
        left: `${width * 0.18}px`,
        bottom: `${width * 0.065}px`,
        fontSize: `${width * 0.03}px`,
        letterSpacing: `${width * 0.005}px`,
      }}>
        Wine Bar + Shop
      </div>
      <a className="ig-link"
        href="https://instagram.com/salutpirouette" 
        target="_blank" alt="instagram" />
    </h1>
  )
}

const Header = () => (
  <header>
    <Logo />
    <div className="announcement block">
      <h1 className="announcement">Arriving Summer 2022</h1>
      <div className="stripes">
        {[...Array(7)].map(_ => <div className="stripe" />)}
      </div>
    </div>
  </header>
)

export default Header;
