import React from 'react';
import Header from './Header';
import ImageBlock from './ImageBlock';

const Section = ({ title, children }) => (
  <section className="section" id={title}>
    {children}
  </section>
)
// can I refactor this like so?
// const Section = ({ title }) => <section className="section" id={title} />

const EmailForm = ({ className="" }) => (
  <form className={`email-signup-form ${className}`}>
    <div className="email-cta">Open your next bottle with us!</div>
    <div className="email-fields">
      <input type="text" name="email" placeholder="Email Address"></input>
      <button>Sign Up</button>
    </div>
    <div className="email-footnote">We will not share your information.</div>
  </form>
)

const Footer = () => (
  <footer>
    <p>4000 Fairfax Drive, Arlington VA 22203</p>
    <a id="email-link" href="mailto:cheers@pirouette.cafe" target="_blank" alt="business email">cheers@pirouette.cafe</a>
  </footer>
)

const ContentBody = ({ sections, children }) => (
  <div id="content-body">
    <p className="display">
      Part modern wine boutique, part comfortable neighborhood bar & 
      restaurant.
    </p>
    <Section title="statement">
      <ImageBlock className="col-1" src="statement.jpg" alt="Two people cheers" stripes={1}/>
      <div className="col-2">
        <div/>
        <p>
          Pirouette is a place of everyday discovery and celebration.
          We welcome guests to taste and take home, or peruse and linger.
        </p>
      </div>
    </Section>
    {sections.map(props => <Section key={`${props.title}-section`} {...props} />)}
    {children}
  </div>
)

const App = () => {
  const sections = [
    {
      title: "experience",
      icon: "cheers.svg",
      children: [
        <h2>
          <span>The</span>
          <span>Experience</span>
        </h2>,
        <ImageBlock content
          src={["experience_1.jpg", "about_3.jpg"]}
          alt={["Cheers at a table", "Friends drinking wine"]}>
          <div className="display">
            An inviting respite for transportive pours and everyday imbibing alike
            <div />
          </div>
        </ImageBlock>,
        <p>
          Celebrate the discovery of new favorites--from winners that whisk you away
          to sun-drenched vineyards to approachable and versatile bottles that earn their
          place at any dinner table.
        </p>,
        <ImageBlock stripes={6}
          src={["experience_3.jpg", "experience_2.jpg"]}
          alt={["Outdoor party table", "A person looks at a bottle of wine"]}
          />,
        <div className="second">
            <h3>The <span>Café</span></h3>
          <ImageBlock stripes={3} content
            src={["experience_4.jpg", "dish.jpg"]} 
            alt={["A couple with wine", "A wonderful dish"]} >
            <p className="display">Enjoy a curated menu of fresh takes on modern cuisine</p>
          </ImageBlock>
        </div>,
      ]
    },
    {
      title: "location",
      icon: "pin.svg",
      children: [
        <h3>
          <span>The </span>
          <span>Space</span>
        </h3>,
        <ImageBlock stripes={4} content
          src={["jsol_large.jpeg", "jsol_art_large.jpg"]}
          alt={["The JSOL building", "Art outside of Pirouette"]} >
          <p className="tagline display">The Heart of Ballston</p>
          <p className="address">
            <span className="street">4000 Fairfax Drive</span>
            <span className="city">Arlington, VA 22203</span>
          </p>
          <p className="blurb">
            Located on the ground floor of the J-SOL luxury apartments,
            we are just across the street from Arlington Central Library and Quincy Park
            and 3 blocks away from the Orange line.
          </p>
        </ImageBlock>
      ]
    },
    {
      title: "about",
      children: [
        <h3>Who <span>we</span> are</h3>,
        <ImageBlock className="first" src="about_1.jpg" alt="The founders of Pirouette" content>
          <p><span>Philippe</span>, <span>Autumn</span>, and <span>Jackie</span></p>
        </ImageBlock>,
        <ImageBlock className="second"
          src={["autumn.jpeg", "about_2.jpeg"]}
          alt={["Executive Chef Autumn", "Philippe and Jackie"]}>
          <p className="title"><span className="orange">Executive Chef</span><span>Autumn Cline</span></p>
          <p>
            <span className="box"/>Growing up in southern Virginia -- dropping off jars of her grandma’s
            homemade preserves at the local canning company, camping in the Blue Ridge 
            mountains -- Autumn honed her palate on local foods and the smell of the 
            land in changing seasons. Her childhood informs her creativity today: 
            a highly adaptable, loosely defined style of cooking rooted in sustainable 
            practices and innovative fermentation with priority given to the preservation 
            of global foodways.
            In 2010 she started her culinary career, cutting her teeth at the late
            DC institution Vidalia. After years of classical French training in the
            infamous brigade-style kitchen, she went on to refine her craft in some
            of the DMV's top restaurants to include Bourbon Steak at Four Seasons,
            Michelin-starred Rose's Luxury, Rappahannock Oyster Bar, and Emilie's
            by Chef Kevin Tien.
            Her culinary accolades include Best Restaurant in America by Bon Appétit Magazine
            in 2014 as part of the Rose's Luxury opening team; Michelin Guide's Bib Gourmand
            in 2016 and Washingtonian's Top 50 Restaurants from 2016 to 2018 as the executive
            chef of Rappahannock Oyster Bar; and Winner of the 2018 DC Central Kitchen
            Capital Food Fight.
          </p>
          <div className="title"><p>Philippe <span className="orange">&</span> Jackie <span className="orange">Loustaunau</span></p></div>
          <p>
            Philippe and Jackie have been residents of Arlington County for many years and
            have deep connections with local businesses and cultural communities. They
            are turning their longstanding love of food and wine and their friendships
            with wine makers, importers, distributors, farmers and chefs to finally go
            all-in on their dream to have a place around the corner where they can get
            good wine and a satisfying bite to eat.
          </p>
        </ImageBlock>,
      ]
    },
    {
      title: "newsletter",
      children: [
        <EmailForm className="col-1"/>,
        <ImageBlock className="col-2" src="clink.jpeg" alt="Cheers!" />,
      ]
    }
  ];

  return (
    <div className="container">
      <Header />
      <ContentBody sections={sections}/>
      <Footer/>
    </div>
  )
}

export default App;
