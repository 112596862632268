import React, { useState, useEffect } from 'react';

const S3Image = ({src, alt}) => (
  <img
  alt={alt}
  src={"https://s3.us-east-1.amazonaws.com/pirouette-dev/" + encodeURIComponent(src)}/>
)

const ImageBlock = ({ src, alt, stripes, content, children, className="" }) => {
  const figures = src instanceof Array ?
    src.map((url, i) => <div className="image-container"><figure key={`figure-${i}`}><S3Image src={url} alt={alt[i]}/></figure></div>
  ) : (
    <div className="image-container"><figure><S3Image src={src} alt={alt}/></figure></div>
  );
  
  const [contentContained, setContentContained] = useState(false);
  if (content) { // when width is small, content is flattened
    const updateDimensions = query => {
      const contained = query.matches;
      setContentContained(contained);
    }
    useEffect(() => {
      var query = window.matchMedia("(min-width: 576px)");
      updateDimensions(query);
      query.addEventListener("change", updateDimensions);
      return () => query.removeEventListener("change", updateDimensions);
    });
  }

  return (
    <div className={`image-block ${className}`}>
      {figures}
      {stripes ? [...Array(stripes)].map(_ => <div className="stripe" />) : ""}
      {contentContained ? <div className="content">{children}</div> : children}
    </div>
  )
}

export default ImageBlock;
